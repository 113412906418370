import React, { useState } from 'react'
import { request } from '../../Redux/Sagas/requests/api'
import './support.scss'

const SupportPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  })
  const [status, setStatus] = useState('')

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setStatus('Submitting...')

    try {
      const response = await request('contact-us', 'POST', formData)

      setStatus('Thank you! Your message has been sent.')
      setFormData({ name: '', email: '', message: '' }) // Reset form
    } catch (error) {
      console.error('Error:', error)
      setStatus('An error occurred. Please try again.')
    }
  }

  return (
    <div className='support-page'>
      <header className='support-header'>
        <a href='/privacy-policy' className='privacy-policy-link'>
          Privacy Policy
        </a>
      </header>
      <main className='support-content'>
        <h1>Support</h1>
        <p>If you have any questions or need assistance, please contact us.</p>
        <form className='support-form' onSubmit={handleSubmit}>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input type='text' id='name' name='name' value={formData.name} onChange={handleChange} placeholder='Your Name' required />
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input type='email' id='email' name='email' value={formData.email} onChange={handleChange} placeholder='Your Email' required />
          </div>
          <div className='form-group'>
            <label htmlFor='message'>Message</label>
            <textarea
              id='message'
              name='message'
              value={formData.message}
              onChange={handleChange}
              placeholder='How can we help you?'
              required></textarea>
          </div>
          <button type='submit' className='submit-btn'>
            Submit
          </button>
        </form>
        {status && <p className='status-message'>{status}</p>}
      </main>
    </div>
  )
}

export default SupportPage
